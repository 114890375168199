import ScrollReveal from "scrollreveal";

export function INIT_AOS() {
  ScrollReveal().reveal('.slide-up', {
    distance: '80px',
    duration: 1800,
    intervall: 16,
    easing: 'cubic-bezier(.15,.8,.4,1)'
  });
}
