function REDIRECT() {
  const REDIRECT_URL_ELEMENT = document.querySelector('[data-redirect]'),
    REDIRECT_URL = REDIRECT_URL_ELEMENT ? REDIRECT_URL_ELEMENT.getAttribute('data-redirect') : false;

  if (REDIRECT_URL) window.location.href = REDIRECT_URL;

}

function COUNT_DOWN(countButton) {
  if (!countButton) return;

  const ACTIVE_COUNTER = countButton.querySelector('[data-count="active"]') || countButton.querySelector('[data-count]:last-of-type'),
    REMOVING_COUNTER = countButton.querySelector('[data-count="removing"]') || false;

  setTimeout(() => {
    let prevCounter = ACTIVE_COUNTER.previousElementSibling || false;

    if (!prevCounter) {
      REDIRECT();
    }
    ACTIVE_COUNTER.setAttribute('data-count', 'removing');
    prevCounter.setAttribute('data-count', 'active');
    if (REMOVING_COUNTER) REMOVING_COUNTER.setAttribute('data-count', '');

    COUNT_DOWN(countButton);
  }, 1000)
}

export function INIT_COUNT_BUTTON() {
  COUNT_DOWN(document.querySelector('[data-counter]'));
}
